import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from '../config/routes.config';
import { MembershipValidationData } from './interfaces/membership-validation-data.interface';
import { ExtendedStudentDetails } from './interfaces/student-details.interface';

@Injectable({
   providedIn: 'root',
})
export class SupplementsService {
   private url: string;

   private studentDetailsUrls: string;

   private syncUrl: string;

   private updateAdminUrl: string;

   private updateManagerUrl: string;

   constructor(private httpClient: HttpClient) {
      this.url = routes.membershipValidationList;
      this.studentDetailsUrls = routes.membershipValidationStudentDetails;
      this.syncUrl = routes.membershipSynced;
      this.updateAdminUrl = routes.membershipUpdateAdmin;
      this.updateManagerUrl = routes.membershipUpdateManager;
   }

   public listMembershipValidations(): Observable<MembershipValidationData[]> {
      return this.httpClient.get<MembershipValidationData[]>(this.url);
   }

   public getDetailedList(uuid: string, lang: string) {
      return this.httpClient.get<ExtendedStudentDetails>(`${this.studentDetailsUrls}`, {
         params: {
            uuid,
            lang,
         },
      });
   }

   public markAsRevised(uuid: string) {
      return this.httpClient.post<any>(`${routes.membershipMarkAsRevised}`, { uuid });
   }

   public markAsTrash(uuid: string) {
      return this.httpClient.post<any>(`${routes.membershipMarkAsTrash}`, { uuid });
   }

   public syncronize(data: any) {
      return this.httpClient.post<any>(`${this.syncUrl}`, data);
   }

   public updateAdmin(data: any): Promise<any> {
      return this.httpClient.patch<any>(`${this.updateAdminUrl}`, data).toPromise();
   }

   public updateManager(data: any): Promise<any> {
      return this.httpClient.patch<any>(`${this.updateManagerUrl}`, data).toPromise();
   }

   public getOne(uuid: string): Promise<MembershipValidationData> {
      return this.httpClient
         .get<MembershipValidationData>(routes.membershipValidationGetOne, {
            params: { uuid },
         })
         .toPromise();
   }
}
