<div>
   <form [formGroup]="personalDataForm" [ngClass]="{ compact: noOfCols === 2 }">
      <div class="row">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.dateOfBirth' | translate }}</mat-label>
            <input
               matInput
               formControlName="dateOfBirth"
               placeholder="19971231"
               [disabled]="true"
            />
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.taxNumber' | translate }}</mat-label>
            <input
               matInput
               formControlName="taxNumber"
               placeholder="8123456789"
               #taxNumberInput="matInput"
               maxlength="10"
               [disabled]="true"
            />
         </mat-form-field>
      </div>
      <div class="row">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.surname' | translate }}</mat-label>
            <input matInput formControlName="surname" #surnameInput="matInput" maxlength="50" />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="surnameInput"
               [helperContent]="surnameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #surnameHelper>
               <p>{{ 'data.surnameHelper' | translate }}</p>
            </ng-template>
            <mat-error>{{ 'fill' | translate }}{{ 'data.surname' | translate }}</mat-error>
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.firstName' | translate }}</mat-label>
            <input matInput formControlName="firstName" #firstNameInput="matInput" maxlength="40" />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="firstNameInput"
               [helperContent]="firstNameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #firstNameHelper>
               <p>{{ 'data.firstNameHelper' | translate }}</p>
            </ng-template>
            <mat-error>{{ 'fill' | translate }}{{ 'data.firstName' | translate }}</mat-error>
         </mat-form-field>
      </div>

      <div class="row">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.middleName' | translate }}</mat-label>
            <input
               matInput
               formControlName="middleName"
               #middleNameInput="matInput"
               maxlength="40"
            />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="middleNameInput"
               [helperContent]="middleNameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #middleNameHelper>
               <p>{{ 'data.middleNameHelper' | translate }}</p>
            </ng-template>
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.email' | translate }}</mat-label>
            <input type="email" matInput formControlName="email" maxlength="100" />
            <mat-error>{{ 'fill' | translate }}{{ 'data.email' | translate }}</mat-error>
         </mat-form-field>
      </div>
      <div class="row">
         <p class="disclaimer">{{ 'data.email-change-disclaimer' | translate }}</p>
      </div>
      

   </form>
</div>

<app-stepper-navigator [hasBack]="false" (next)="onSubmit()"></app-stepper-navigator>
