export const routes = {
   person: '/api/person',
   upload: 'api/person/upload',
   documents: 'api/person/documents',
   city: '/api/city',
   zipcode: '/api/city/zip-code',
   country: '/api/country',
   streetType: '/api/street-type',
   nationality: '/api/nationality',
   address: '/api/address',
   school: 'api/school',
   faculty: 'api/faculty',
   course: 'api/course',
   languageSkill: 'api/language-skill',
   studies: 'api/studies',
   questions: 'api/questions',
   questionAnswer: 'api/question-answer',
   entryForm: 'api/entry-form',
   startPayment: 'api/payment/start-payment',
   finishPayment: 'api/payment/finish-payment',
   auth: 'api/auth',
   area: 'api/area',
   bankAccount: 'api/bank-account',
   registered: 'api/person/registered',
   paymentLink: 'api/person/payment-link',
   emailLog: 'api/email-log',
   membershipValidationList: 'api/membership-validation/list',
   membershipValidationGetOne: 'api/membership-validation/one',
   membershipValidation: 'api/membership-validation',
   membershipValidationUpload: 'api/membership-validation/upload',
   membershipValidationDocuments: 'api/membership-validation/documents',
   membershipValidationDocumentList: 'api/membership-validation/documentlist',
   membershipValidationStudentDetails: '/api/membership-validation/details',
   membershipMarkAsRevised: '/api/membership-validation/status/revised',
   membershipMarkAsTrash: '/api/membership-validation/status/trash',
   membershipSynced: '/api/membership-validation/sync',
   missingDataSend: '/api/missing-data/send',
   membershipValidationFinish: 'api/membership-validation/finish-application',
   membershipUpdateAdmin: '/api/membership-validation/update-admin',
   membershipUpdateManager: '/api/membership-validation/update-manager',
};
